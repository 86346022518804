<script>
import draggable from 'vuedraggable'

import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import { monitorMethods, userMethods } from '@/state/helpers'
import { getApi } from '@/api'

/**
 * Patients Board Component
 */
export default {
  page: {
    title: 'Patients Board',
    meta: [{ name: 'description' }],
  },
  components: {
    draggable,
    Layout,
    PageHeader
  },
  data () {
    return {
      exercisesTodo: [],
      exercisesAvailable: [],
      title: 'Patients Board',
      selectedPatientId: 0,
      items: [
        {
          text: 'VRsteps',
          href: '/',
        },
        {
          text: 'Patients Board',
          active: true,
        },
      ],
    }
  },
  methods: {
    ...userMethods,
    ...monitorMethods,
    ...getApi(),
    exerciseList (list) {
      return list.reduce((lis, cat) => lis.concat(cat.submenu.map(m => {
        m.category = {
          title: cat.title,
          description: cat.description,
          image: cat.image + '?unity=true'
        }
        m.image = m.image + '?unity=true'
        return m
      })), []).filter(exer => exer.scene > 0)
    }
  },
  mounted () {

    let userId = this.$store.state.user.id
    let accessToken = this.$store.state.user.access_token

    this.getPatients(userId, accessToken).then(response => response.json()).then(
        (results) => {
          this.setPatients({ patients: results.patients })
          // console.log('patients', this.$store.state.monitor.patients)
        }
    )
  },
  watch: {
    selectedPatientId: function (patientId) {

      this.exercisesTodo = []

      let userId = this.$store.state.user.id
      let accessToken = this.$store.state.user.access_token

      this.getExercises(userId, accessToken, patientId).then(response => response.json()).then(
          (results) => {
            // console.log('exercises', results.menu_all)
            //  this.exercisesAvailable = results.menu_all
            this.exercisesAvailable = this.exerciseList(Object.values(results.menu_all))
            console.log(this.exercisesAvailable)
          }
      )
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row mb-2">
      <div class="col-lg-3">
        <select v-model="selectedPatientId"
                class="form-control"
                name="patient">
          <option value="0" selected>Select your patient</option>
          <option
              :key="'p-1'+k" v-for="(p,k) in $store.state.monitor.patients"
              :value="p.id">
            {{ p.name }}
          </option>
        </select>
      </div>
      <div class="col-lg-9">
        <div class="text-lg-right">
          <ul class="list-inline mb-0">
            <li class="list-inline-item" :class="{unselected: selectedPatientId>0 && selectedPatientId!==p.id}"
                :key="'p-1'+k" v-for="(p,k) in $store.state.monitor.patients">
              <a @click="selectedPatientId=p.id"
                 href="javascript: void(0);"
                 class="d-inline-block"
                 v-b-tooltip.hover
                 :title="p.name">
                <img
                    :src="p.image"
                    class="rounded-circle avatar-xs"
                    alt
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-if="selectedPatientId">
      <div class="col-lg-6">
        <div class="card-body">
          <b-dropdown right class="float-right" variant="white">
            <template v-slot:button-content>
              <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
            </template>
            <b-dropdown-item>Edit</b-dropdown-item>
            <b-dropdown-item>Delete</b-dropdown-item>
          </b-dropdown>

          <!-- end dropdown -->
          <h4 class="card-title">Exercises Todo</h4>
          <p class="mb-0">{{ exercisesTodo.length }} Tasks</p>
        </div>
        <div class="card">
          <div class="card-body border-bottom">
            <div id="todo-task" class="task-list">
              <draggable class="list-group" group="tasks" :list="exercisesTodo">
                <div
                    class="card task-box"
                    v-for="exercise in exercisesTodo"
                    :key="exercise.scene"
                >
                  <div
                      class="progress progress-sm animated-progess"
                      style="height: 3px"
                  >
                    <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 72%"
                        aria-valuenow="72"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="card-body">
                    <div class="float-right ml-2">
                      <div>Category: {{ exercise.category.title }}</div>
                    </div>
                    <div class="mb-3">
                      <a href="#" class>#{{ exercise.scene }} - {{ exercise.title }}</a>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <img class="mb-2 shadow" :src="`${exercise.image}`" width="100%" height="auto" alt>
                        <p class="mb-2">{{ exercise.description }}</p>
                      </div>
                      <div class="col-6">
                        <img class="mb-2 shadow" :src="`${exercise.category.image}`" width="100%" height="auto" alt>
                        <p class="mb-2 line-clap" :title="exercise.category.description">
                          {{ exercise.category.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <!-- end task card -->

              <div class="text-center">
                <a
                    href="javascript: void(0);"
                    class="btn btn-primary mt-1 waves-effect waves-light"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add New
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card-body">
          <b-dropdown right class="float-right" variant="white">
            <template v-slot:button-content>
              <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
            </template>
            <b-dropdown-item>Edit</b-dropdown-item>
            <b-dropdown-item>Delete</b-dropdown-item>
          </b-dropdown>
          <!-- end dropdown -->
          <h4 class="card-title">Available Exercises</h4>
          <p class="mb-0">{{ exercisesAvailable.length }} Tasks</p>
        </div>
        <div class="card">
          <div class="card-body border-bottom">
            <div id="inprogress-task" class="task-list">
              <draggable class="list-group" group="tasks" :list="exercisesAvailable">
                <div
                    class="card task-box"
                    v-for="exercise in exercisesAvailable"
                    :key="exercise.scene"
                >
                  <div
                      class="progress progress-sm animated-progess"
                      style="height: 3px"
                  >
                    <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="card-body">
                    <div class="float-right ml-2">
                      <div>Category: {{ exercise.category.title }}</div>
                    </div>
                    <div class="mb-3">
                      <a href="#" class>#{{ exercise.scene }} - {{ exercise.title }}</a>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <img class="mb-2 shadow" :src="`${exercise.image}`" width="100%" height="auto" alt>
                        <p class="mb-2">{{ exercise.description }}</p>
                      </div>
                      <div class="col-6">
                        <img class="mb-2 shadow" :src="`${exercise.category.image}`" width="100%" height="auto" alt>
                        <p class="mb-2 line-clap" :title="exercise.category.description">
                          {{ exercise.category.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="text-center">
                <a
                    href="javascript: void(0);"
                    class="btn btn-primary mt-1 waves-effect waves-light"
                >
                  <i class="mdi mdi-plus mr-1"></i> Add New
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
